import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const GeneralHeader = ({children, isCentered}) => {
  const Head = styled("header")`
    color: #11784a;
    font-family: "Roboto", sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: ${isCentered ? "3rem" : "3.25rem"}; /* 162.5% */
    text-align: ${isCentered ? "center" : "left"};
  `;

  return (
    <Head>
      {children}
    </Head>
  )
}

GeneralHeader.propTypes = {
  children: PropTypes.node,
  isCentered: PropTypes.bool,
}

export default GeneralHeader
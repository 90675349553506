import React from 'react'
import FooterText from '../atoms/texts/FooterText'
import PropTypes from 'prop-types'

const Footer = props => {
  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  const currentYear = getCurrentYear()

  return (
    <footer id='footer' className="h-auto lg:h-[13.375rem] p-[2rem] lg:px-[3.75rem] lg:py-[1.75rem] bg-[#117E4D] flex items-end">
      <div className="flex flex-col lg:flex-row items-center gap-[2rem] justify-between w-full">
        <div className="">
          <FooterText>©{currentYear} Amorserv Tech. All rights reserved</FooterText>
        </div>
        <div className="flex flex-col lg:flex-row gap-[1rem] items-center lg:gap-[2.19rem]">
          <FooterText>Privacy & Policy</FooterText>
          <FooterText>Terms & Condition</FooterText>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {}

export default Footer
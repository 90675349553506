import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";

const Label = ({ children }) => {
  const Label = styled("label")`
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto", san-serif;
    font-size: 0.86425rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.71694rem; /* 82.958% */
    letter-spacing: 0.00894rem;
    display: block;
  `;

  return <Label>{children}</Label>;
};

Label.propTypes = {
  children: PropTypes.node,
};

export default Label;

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";

const SubmitButton = ({ children, disabled, loading }) => {
  const ButtonWrapper = styled("button")`
    display: flex;
    width: 8.918rem;
    height: 2.73763rem;
    padding: 0rem 1.37638rem;
    justify-content: center;
    align-items: center;
    gap: 0.55056rem;
    flex-shrink: 0;
    border-radius: 0.27525rem;
    background: #0e603a;
    opacity: ${disabled ? "0.8" : "1"};
    cursor: ${disabled ? "not-allowed" : "pointer"};
  `;

  const ButtonText = styled("span")`
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.10106rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.65163rem */
    letter-spacing: -0.022rem;
  `;

  // return (
  //   <ButtonWrapper type="submit" className="">
  //     <ButtonText> {loading ? "Sending..." : children}</ButtonText>
  //   </ButtonWrapper>
  // );

  return (
    <button
      disabled={disabled}
      className={`bg-[#0e603a] flex w-[8.918rem] h-[2.73763rem] justify-center items-center gap-[0.55056rem] shrink-0  text-white text-center text-[1.10106rem] not-italic font-semibold leading-[150%] tracking-[-0.022rem] px-[1.37638rem] py-0 rounded-[0.27525rem] outline-none font-Roboto ${disabled ? "opacity-80 cursor-not-allowed" : ""}`}
    >
      {children}
    </button>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SubmitButton;

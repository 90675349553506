import React from 'react'
import SectionHeader from '../atoms/texts/SectionHeader'
import GeneralHeader from '../atoms/texts/GeneralHeader'
import CenteredParagraph from '../atoms/texts/CenteredParagrapgh'

const ReasonsToChoose = () => {
  const reasons = [
    {
      title: "Technical Expertise",
      body: "Our wide-ranging expertise includes qualifications from renowned vendors.",
    },
    {
      title: "Local and Global Presence",
      body: "We ensure consistent, high-quality service for clients' local and international operations.",
    },
    {
      title: "Strong Team Backup",
      body: "Our experts work collaboratively with client's team to deliver effective assistance.",
    },
    {
      title: "Cost-Efficient",
      body: "We offer cost-effective add-on IT solutions, for projects needs.",
    },
    {
      title: "Top-Tier resources",
      body: "Our consultants access top-notch resources for projects worldwide.",
    },
    {
      title: "Streamlined Procurement",
      body: "Our strong IT channel relationships enable us to procure resources and manage tech services efficiently.",
    },
  ];


  return (
    <div className="bg-[#EFF3FD] p-[2rem] lg:px-[3.75rem] lg:py-[4.19rem]">
      <div className="mb-[6.3rem]">
        <div className="max-w-[59.3125rem] container mx-auto">
          <SectionHeader>
            <p className="text-center">
              The reasons to choose Amorserv Tech to provide you with IT
              solution are clear
            </p>
          </SectionHeader>
        </div>
        <div className="max-w-[36.5625rem] container mx-auto">
          <CenteredParagraph>
            Our assured top-tier service provides unparalleled peace of mind for
            your global team. With our industry experience and cutting-edge
            technologies, Operational efficiency and productivity comes first
            for our customers.
          </CenteredParagraph>
        </div>
      </div>
      <div className="max-w-[60rem container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[2.81rem] mb-[2rem] lg:mb-[8.5rem]">
          {reasons.slice(0, 3).map(({ body, title }) => (
            <div key={title} className="">
              <h4 className="text-[#11784A] text-[1.8rem] not-italic font-bold leading-10 text-center font-Roboto truncate">
                {title}
              </h4>
              <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
                {body}
              </p>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[2.81rem] mb-[2rem]">
          {reasons.slice(-3).map(({ body, title }) => (
            <div key={title} className="">
              <h4 className="text-[#11784A] text-[1.8rem] not-italic font-bold leading-10 text-center font-Roboto truncate">
                {title}
              </h4>
              <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
                {body}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReasonsToChoose
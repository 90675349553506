import React from 'react'
import GeneralHeader from '../atoms/texts/GeneralHeader'
import CaseStudyHeader from '../atoms/texts/CaseStudyHeader';
import CaseStudyParagraph from '../atoms/texts/CaseStudyParagraph';
import CaseStudyParagraphHeaderLight from '../atoms/texts/CaseStudyParagraphHeaderLight';
import caseStudyImg from "../../assets/case-study/laptop-network-data-center-with-black-woman-it-support-engineer-working-dark-server-room-computer-cybersecurity-analytics-with-female-programmer-problem-solving-troubleshooting 1.png"

const CaseStudy = () => {
  return (
    <div className="p-[1.5rem] lg:px-[3.75rem] lg:py-[1.35rem]">
      <h2 className="text-[#11784A] text-[2rem] not-italic font-bold leading-[3.25rem] mb-[5.6rem] text-center">
        Case Study
      </h2>
      <div className="flex flex-col-reverse lg:flex-row gap-[5rem]">
        <div className="space-y-[2rem] lg:basis-[55%]">
          <h3 className="text-[#010101] text-[2.8125rem] not-italic font-bold leading-[3.25rem] font-Roboto">
            ABNS Seamlessly completed 5 cabling installations in Illinois with
            Amorserv Tech
          </h3>
          <div className="space-y-[2rem]">
            <p className="text-[#010101] text-justify text-lg not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              A vital success factor was our fast response and instance
              commencement of the project at each location.
            </p>
            <p className="text-[#010101] text-justify text-lg not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              “Amorserv Tech proved to be our ultimate partner in executing the
              cabling installation for the fast-food locations in Illinois.
              Their swift response, precision, and commitment to meeting tight
              deadlines were truly impressive. The project was completed on
              time, within budget, and with exceptional professionalism."
            </p>
          </div>
          <p className="text-[#010101] text-4xl not-italic font-normal leading-7 font-Roboto">
            ABNS.
          </p>
        </div>
        <div className="lg:basis-[45%] aspect-square w-full lg:max-h-[30rem]">
          <img src={caseStudyImg} alt="n8yh" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
}

export default CaseStudy

export const isNotEmpty = (value) => value.trim().length > 0 

export function isValidEmail(email) {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // return emailPattern.test(email);
  if (email.trim().length === 0) return false
  if (!emailPattern.test(email)) {
    return false
  } else {
    return true
  }
}

export function isPhoneNumber(value) {
  // Regular expression to match numbers, dashes, and brackets
  const regex = /^[0-9\-()\[\]]{7,15}$/;

  // Test if the value matches the regex
  return regex.test(value);
}

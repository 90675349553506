import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material';

const TextArea = ({ value, onChange, name, validator }) => {
  const Area = styled("textarea")`
    border: 1.728px solid #000;
    background: rgba(255, 255, 255, 0.25);
    width: 100%;
    height: 100%;
  `;

  return (
    <textarea
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value, name, validator)}
      className="border-[1.728px] border-black bg-[rgba(255,255,255,0.25)] w-full h-full p-2"
    ></textarea>
  );
};

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea
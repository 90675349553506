import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const SectionHeader = ({children}) => {
const Head = styled("header")`
  color: #11784a;
  font-family: "Roboto", sans-serif;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.25rem; /* 115.556% */
`;

  return (
    <Head>{children}</Head>
  )
}

SectionHeader.propTypes = {
  children: PropTypes.node
}

export default SectionHeader
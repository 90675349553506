import React from 'react'
// import SectionHeader from '../atoms/texts/SectionHeader'
// import SectionParagraph from '../atoms/texts/SectionParagraph'
// import GeneralButton from '../atoms/buttons/GeneralButton'
// import aboutUs1Img from "../../assets/about-us/about-us-1.png"
// import aboutUs2Img from "../../assets/about-us/about-us-2.png"
// import aboutUs3Img from "../../assets/about-us/about-us-3.png"
// import aboutUs4Img from "../../assets/about-us/about-us-4.png"
import checkIcon from "../../assets/icons/check-mark.png";
import aboutUs1 from "../../assets/about-section/about-us-1.png";
import aboutUs2 from "../../assets/about-section/about-us-2.png";
import aboutUs3 from "../../assets/about-section/about-us-3.png";
import aboutUs4 from "../../assets/about-section/about-us-4.png";

const AboutUs = () => {
  const goToForm = () => {
    const formEl = document.querySelector("#contact-us-form");
    if (formEl) {
      formEl.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="lg:py-[1.35rem] space-y-[5rem]">
      <div className="flex flex-col p-[1.5rem] lg:px-[3.75rem] lg:flex-row lg:gap-[8rem]">
        <div className="basis-[60%] max-h-[30rem] ">
          <img src={aboutUs4} alt="n8yh" className="w-full h-full" />
        </div>
        <div className="space-y-[2rem] lg:basis-[50%]">
          <h3 className="text-[#11784A] text-[2.8125rem] not-italic font-bold leading-[3.25rem] font-Roboto">
            Minimize Downtime, Maximize Operations.
          </h3>
          <div className="space-y-[2rem]">
            <p className="text-[#010101] text-xl not-italic font-normal leading-7">
              Our scalable and adaptable field support services will optimize
              your business operations and maximize your profits.
            </p>
            <p className="text-[#010101] text-xl not-italic font-normal leading-7">
              Whether you’re experiencing difficulty in managing your IT
              infrastructure, reducing high maintenance costs, or system
              downtime and technical issue - Amorserv tech is your best bet.
            </p>
          </div>
          <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
            I'm Interested
          </button>
        </div>
      </div>
      <div className="flex flex-col p-[1.5rem] lg:px-[3.75rem] bg-[#FAFAFB] lg:flex-row-reverse lg:gap-[8rem]">
        <div className="basis-[55%] max-h-[32rem] self-center">
          <img src={aboutUs3} alt="n8yh" className="w-full h-full" />
        </div>
        <div className="space-y-[2rem] basis-[50%]">
          <h3 className="text-[#11784A] text-[2.8125rem] not-italic font-bold leading-[3.25rem] font-Roboto">
            We will help you resolve technical issues which can lead to
            operations failure.
          </h3>
          <ul className="p-[1rem] space-y-[1.56rem]">
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7 bg-no-repeat">
                Our service team will swiftly respond to your service request
                within 10 minutes.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                Get expert support with our onsite team, skilled in latest tools
                and technology usage.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                We proactively monitor and maintain your vital systems to allow
                you focus on what matters most “your business success”.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                We will continuously provide you with the latest technological
                trends, to stay ahead of competitions.
              </p>
            </li>
          </ul>
          <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
            I'm Interested
          </button>
        </div>
      </div>
      <div className="flex flex-col p-[1.5rem] lg:px-[3.75rem] lg:flex-row lg:gap-[8rem]">
        <div className="basis-[60%] max-h-[30rem] self-center">
          <img src={aboutUs2} alt="n8yh" className="w-full h-full" />
        </div>
        <div className="space-y-[2rem] basis-[50%]">
          <h3 className="text-[#11784A] text-[2.8125rem] not-italic font-bold leading-[3.25rem] font-Roboto">
            We will help you optimize operations, by reducing high maintenance
            cost.
          </h3>
          <ul className="p-[1rem] space-y-[1.56rem]">
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7 bg-no-repeat">
                We’ll provide you with cutting-edge Tech support to streamline
                operations saving your internal resources.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                We’ll help you detect potential issues before they escalate,
                preventing operations disruption.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                Create customized maintenance plans to address your business’s
                financial strength.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                Give you insight into effective resources allocation through
                optimization of your IT infrastructure.
              </p>
            </li>
          </ul>
          <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
            I'm Interested
          </button>
        </div>
      </div>
      <div className="flex flex-col p-[1.5rem] lg:px-[3.75rem] bg-[#FAFAFB] lg:flex-row-reverse lg:gap-[8rem]">
        <div className="basis-[55%] max-h-[32rem] self-center">
          <img src={aboutUs1} alt="n8yh" className="w-full h-full" />
        </div>
        <div className="space-y-[2rem] basis-[50%]">
          <h3 className="text-[#11784A] text-[2.8125rem] not-italic font-bold leading-[3.25rem] font-Roboto">
            Ensure smooth transition of your business, totally avoiding IMAC
            hassles.
          </h3>
          <ul className="p-[1rem] space-y-[1.56rem]">
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7 bg-no-repeat">
                From installations to moves, additions, and changes, we handle
                IT transitions with precision and ease.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                Our expert team ensures seamless IMAC service, minimizing
                disruptions to your operations.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                We provide customized IMAC services that fit your specific
                business needs, no matter the scale.
              </p>
            </li>
            <li className="" style={{ listStyleImage: `url('${checkIcon}')` }}>
              <p className="text-[#010101] text-xl not-italic font-normal leading-7">
                With Amorserv Tech, experience quick turnaround times for IMAC
                requests, keeping your business agile. 
              </p>
            </li>
          </ul>
          <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
            I'm Interested
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUs
import React from 'react'
import FeatureCard from '../molecules/FeatureCard';
import GeneralHeader from '../atoms/texts/GeneralHeader';
import SectionParagraph from '../atoms/texts/SectionParagraph';
import GeneralButton from '../atoms/buttons/GeneralButton';

const Services = () => {
    const goToForm = () => {
      const formEl = document.querySelector("#contact-us-form");
      if (formEl) {
        formEl.scrollIntoView({ behavior: "smooth" });
      }
    };

  const features = [
    {
      title: "Onsite Support",
      message:
        "Our Expert technicians provide hands-on support tailored to your business needs, ensuring swift issue resolution and minimal disruptions.",
    },
    {
      title: "Smart Hand",
      message:
        "Streamline IT processes, provides proactive monitoring, and offers tailored maintenance plans to optimize productivity, reduce downtime, and ultimately save costs for your business.",
    },
    {
      title: "Asset Management",
      message:
        "Streamline resource allocation, tracking, and maintenance with strategic asset management solutions, maximizing efficiency and cost-effectiveness.",
    },
    {
      title: "Ekahau Survey and Design",
      message:
        "Achieve optimal wireless network coverage and performance with advanced Ekahau survey and design services.",
    },
    {
      title: "IT Project Management",
      message:
        "Our experienced team oversees IT projects from conception to completion, ensuring smooth execution and successful outcomes.",
    },
    {
      title: "Structured Cabling",
      message:
        "Establish robust network connections with structured cabling solutions, supporting data transfer and communications with utmost reliability.",
    },
  ];

  return (
    <div className="bg-[#EFF3FD] p-[1.5rem] lg:px-[3.75rem] lg:py-[1.35rem]">
      <h2 className="text-[#11784A] text-[2rem] not-italic font-bold leading-[3.25rem] mb-[5.6rem] text-center">
        Explore Our Diverse Services
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-[10rem] gap-y-[3.6rem] mx-auto container max-w-[60rem] mb-[4.75rem]">
        <div className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.30),0px_8px_12px_6px_rgba(0,0,0,0.15)] rounded-[2.1875rem] bg-[#EFF3FD] px-[3.88rem] py-[6rem]">
          <div className="">
            <h4 className="text-[#11784A] text-center text-2xl not-italic font-bold leading-[1.53194rem] font-Roboto mb-[0.62rem]">
              Onsite Support
            </h4>
            <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              Our Expert technicians provide hands-on support tailored to your
              business needs, ensuring seamless operations, timely solutions,
              and unmatched reliability.
            </p>
          </div>
        </div>
        <div className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.30),0px_8px_12px_6px_rgba(0,0,0,0.15)] rounded-[2.1875rem] bg-[#EFF3FD] px-[3.88rem] py-[6rem]">
          <div className="">
            <h4 className="text-[#11784A] text-center text-2xl not-italic font-bold leading-[1.53194rem] font-Roboto mb-[0.62rem]">
              Smart Hands
            </h4>
            <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              From troubleshooting to on-site support, we've got you covered
              with precision and speed. Focus on your core business, while we
              handle the rest with expertise and care.
            </p>
          </div>
        </div>
        <div className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.30),0px_8px_12px_6px_rgba(0,0,0,0.15)] rounded-[2.1875rem] bg-[#EFF3FD] px-[3.88rem] py-[6rem]">
          <div className="">
            <h4 className="text-[#11784A] text-center text-2xl not-italic font-bold leading-[1.53194rem] font-Roboto mb-[0.62rem]">
              IT Project Management
            </h4>
            <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              Our experienced team oversees IT projects from conception to
              completion, ensuring smooth execution and successful outcomes.
            </p>
          </div>
        </div>
        <div className="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.30),0px_8px_12px_6px_rgba(0,0,0,0.15)] rounded-[2.1875rem] bg-[#EFF3FD] px-[3.88rem] py-[6rem]">
          <div className="">
            <h4 className="text-[#11784A] text-center text-2xl not-italic font-bold leading-[1.53194rem] font-Roboto mb-[0.62rem]">
              Structured Cabling
            </h4>
            <p className="text-[#010101] text-center text-base not-italic font-medium leading-6 tracking-[0.00938rem] font-Roboto">
              Establish robust network connections with structured cabling
              solutions, supporting data transfer and communications with utmost
              reliability.
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
          I'm Interested
        </button>
      </div>
    </div>
  );
}

export default Services
import Home from './components/pages/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

function App() {
  const router = createBrowserRouter([
    {index: true, element: <Home/>}
  ])

  return <RouterProvider router={router}/>;
}

export default App;

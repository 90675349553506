import React from 'react'
import PropTypes from 'prop-types'
import HeroHeaderText from '../atoms/texts/HeroHeaderText'
import HeroHeaderParagraph from '../atoms/texts/HeroHeaderParagraph'
import GeneralButton from '../atoms/buttons/GeneralButton'
import heroImg from "../../assets/saver-guy-1.png";
import diamond from "../../assets/Vector.png"

const Header = props => {
const goToForm = () => {
  const formEl = document.querySelector("#contact-us-form");
  if (formEl) {
    formEl.scrollIntoView({ behavior: "smooth" });
  }
};

  return (
    <div className="flex relative">
      <img
        src={diamond}
        alt="gfy"
        className="absolute w-[26.375rem] h-[23.15519rem] fill-[rgba(120,36,17,0.10)] top-[18.5rem] right-[16rem] z-20 hidden lg:block"
      />
      <div className="p-[1.5rem] lg:flex lg:basis-[52%] lg:items-center lg:justify-center lg:px-[0rem] lg:py-[0rem] lg:pl-[3.75rem]">
        <div className="">
          <h1 className="text-[#11784A] font-Roboto text-[2.5rem] lg:text-[3.5625rem] font-extrabold lg:font-black leading-tight lg:leading-[4rem] tracking-[-0.01563rem] mb-[3.88rem]">
            Simplify Your Tech Needs With Cost-Effective Field Technical
            Services.
          </h1>
          <p className="mb-[3.12rem] text-black font-Roboto text-[1.25rem] font-[500] leading-[2rem]">
            Streamline your IT challenges effectively with ease. We implement
            cost-effective project management and field technical services with
            high success rate.
          </p>
          <button onClick={goToForm} className="w-full md:w-[17.21306rem] h-[4.5625rem] justify-center items-center gap-[0.82956rem] px-[2.07388rem] py-0 rounded-[0.41475rem] bg-[#11784a] text-white text-center text-[1.65906rem] not-italic font-semibold leading-[150%] tracking-[-0.03319rem] hover:bg-[#0f6241] transition-colors duration-300 ease-in-out">
            Get Started
          </button>
        </div>
      </div>
      <div
        className="h-[45.625rem] basis-[48%] hidden lg:block"
        style={{
          background: `url('${heroImg}'), white 50% / cover no-repeat`,
          zIndex: "10",
        }}
      ></div>
    </div>
  );
}

Header.propTypes = {}

export default Header
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const FooterText = ({children}) => {
  const Text = styled("p")`
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 1.5rem */
    letter-spacing: -0.02rem;
  `;

  return (
    <Text>{children}</Text>
  )
}

FooterText.propTypes = {
  children: PropTypes.node
}

export default FooterText
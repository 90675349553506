import React from 'react'
import NavBar from '../templates/NavBar'
import Header from '../templates/Header'
import PartnersSection from '../templates/PartnersSection'
import AboutUs from '../templates/AboutUs'
import Services from '../templates/Services';
import CaseStudy from '../templates/CaseStudy'
import ReasonsToChoose from '../templates/ReasonsToChoose'
import JoinForm from '../templates/JoinForm'
import Footer from '../templates/Footer'

const Home = props => {
  return (
    <div className="bg-white space-y-[2rem]">
      <div className=''>
        {/* NAVBAR */}
        <NavBar />
        {/* HERO SECTION */}
        <Header />
      </div>

      {/* PARTNERS SECTION */}
      <PartnersSection />

      {/* ABOUT US SECTION */}
      <AboutUs />

      {/* SERVICES */}
      <Services />

      {/* CASE STUDY */}
      <CaseStudy />

      {/* REASONS TO CHOOSE */}
      <ReasonsToChoose />

      {/* CONTACT US FORM */}
      <JoinForm />

      {/* FOOTER */}
      <Footer />
    </div>
  );
}

Home.propTypes = {}

export default Home
import React, { useState } from "react";
import GeneralHeader from "../atoms/texts/GeneralHeader";
import CenteredParagraph from "../atoms/texts/CenteredParagrapgh";
import SubmitButton from "../atoms/buttons/SubmitButton";
import Alerts from "../atoms/alerts/Alerts";
import Label from "../atoms/inputs/Label";
import Input from "../atoms/inputs/Input";
import TextArea from "../atoms/inputs/TextArea";
import {
  isNotEmpty,
  isValidEmail,
  isPhoneNumber,
} from "../../utils/validations";

const JoinForm = () => {
  const form = {
    name: "",
    nameIsValid: null,
    email: "",
    emailIsValid: null,
    company: "",
    companyIsValid: null,
    phone: "",
    phoneIsValid: null,
    message: "",
    messageIsValid: null,
    error: null,
    errorMessage: "",
    successful: null,
    successMessage: "",
    loading: false,
  };

  const [formState, setFormState] = useState(form);
    const { emailIsValid, phoneIsValid, nameIsValid, messageIsValid, companyIsValid, email, phone, name, message, company } =
      formState;


  const handleChange = (value, inputName, validator) => {
    setFormState((previous) => {
      return {
        ...previous,
        [inputName]: value,
        [`${inputName}IsValid`]: validator(value),
      };
    });
  };

  const scrollToAlertEl = () => {
    const alertEl = document.querySelector(".alert")
    if(alertEl){
      console.log(alertEl);
      alertEl.scrollIntoView({behavior: "smooth"})
    }
  }

  const sendDetails = async () => {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: name,
        email: email,
        phone: phone,
        message: message,
        companyName: company
      }),
    };

    try {
      const url = `https://app.amorservtech.net/tech-landing-contact`;
      const response = await fetch(url, config);
      const result = await response.json();
      console.log({result});
      if(result.code === 200){
        setFormState(pv => ({
          ...pv,
          ...form,
          successMessage: "Your details have been sent successfully, we will contact you soon",
          successful: true,
          error: false,
        }))
      }else{
        setFormState((pv) => ({
          ...pv,
          errorMessage: result.message,
          error: true,
          successful: false,
        }));
      }
      // console.log({result});
      setFormState((pv) => ({ ...pv, loading: false }));
      scrollToAlertEl()
      return result;
    } catch (err) {
      console.error(err);
      setFormState((pv) => ({
        ...pv,
        errorMessage: "Failed to send, please try again soon.",
        error: true,
        successful: false,
      }));
      setFormState((pv) => ({ ...pv, loading: false }));
      scrollToAlertEl()
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState((pv) => ({ ...pv, loading: true }));
    sendDetails();
  };

  const isDisabled = !(emailIsValid)
  const loading = formState.loading
  // console.log(isDisabled);

  return (
    <div className="bg-[#FAFAFB] p-[2rem] lg:px-[3.75rem] lg:py-[4.19rem] flex items-center justify-center">
      <form
        id="contact-us-form"
        onSubmit={handleSubmit}
        className="bg-white p-[1rem] lg:px-[13.6rem] lg:py-[1.75rem] shadow-[0px_2.6546683311462402px_2.6546683311462402px_0px_rgba(0,0,0,0.25)] rounded-[0.82956rem]"
      >
        <div className="mb-[3.56rem]">
          <GeneralHeader isCentered={true}>
            Get started with Amorserv Today!
          </GeneralHeader>
          <div className="max-w-[29.4375rem] container mx-auto">
            <CenteredParagraph>
              Join the other companies experiencing business expansion and
              operational efficiency.
            </CenteredParagraph>
          </div>
        </div>
        {formState.error === true && (
          <Alerts forErrors={true}>{formState.errorMessage}</Alerts>
        )}
        {formState.successful === true && (
          <Alerts >{formState.successMessage}</Alerts>
        )}
        <div className="max-w-[32.13888rem] container mx-auto space-y-[1.5rem] mb-[1.67rem]">
          <div className="">
            <div className="mb-[0.3rem] lg:mb-[0.7rem]">
              <Label>Name</Label>
            </div>
            <Input
              value={formState.name}
              name={"name"}
              onChange={handleChange}
              validator={isNotEmpty}
              type={"text"}
            />
          </div>
          <div className="">
            <div className="mb-[0.3rem] lg:mb-[0.7rem]">
              <Label>Email*</Label>
            </div>
            <Input
              value={formState.email}
              name={"email"}
              onChange={handleChange}
              validator={isValidEmail}
              type={"email"}
            />
            {
              emailIsValid === false &&
            <p className="mt-2 text-sm text-red-600 font-medium">
              {isNotEmpty(email) ? "Email is invalid" : "Email is required"}
            </p>
            }
          </div>
          <div className="">
            <div className="mb-[0.3rem] lg:mb-[0.7rem]">
              <Label>Company Name</Label>
            </div>
            <Input
              value={formState.company}
              name={"company"}
              onChange={handleChange}
              validator={isNotEmpty}
              type={"text"}
            />
          </div>
          <div className="">
            <div className="mb-[0.3rem] lg:mb-[0.7rem]">
              <Label>Phone Number</Label>
            </div>
            <Input
              value={formState.phone}
              name={"phone"}
              onChange={handleChange}
              validator={isPhoneNumber}
              type={"tel"}
            />
          </div>
          <div className="">
            <div className="mb-[0.3rem] lg:mb-[0.7rem]">
              <Label>Message</Label>
            </div>
            <div className="w-full h-[10rem]">
              <TextArea
                value={formState.message}
                name={"message"}
                onChange={handleChange}
                validator={isNotEmpty}
              />
            </div>
          </div>
          <div className="">
            <SubmitButton disabled={isDisabled} loading={loading}>
              {loading ? "Sending..." : "Message"}
            </SubmitButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JoinForm;

import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const Input = ({value, onChange, name, validator, type}) => {
  return (
    <input name={name} type={type || "text"} className='border-[1.728px] border-[#000] w-full font-Roboto p-2' value={value} onChange={(e) => onChange(e.target.value, name, validator)}></input>
  )
}

Input.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
}

export default Input
import React from 'react'
import GeneralHeader from '../atoms/texts/GeneralHeader'
import keplrLogo from "../../assets/partners/keplr1.png"
import beneschLogo from "../../assets/partners/benesch1.png"
import esiLogo from "../../assets/partners/esi1-01 1.png"
import safetyLogo from "../../assets/partners/telnyx1 1.png"
import adventLogo from "../../assets/partners/advent 2.png"

const PartnersSection = () => {
  return (
    <div className="p-[1.5rem] lg:px-[3.75rem] lg:py-[1.35rem]">
      <h2 className="text-[#11784A] text-[2rem] not-italic font-bold leading-[3.25rem] mb-[1.9rem] text-center">
        Some brands that trust us
      </h2>
      <div className="mx-auto container mb-[2.95rem]">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-[1rem] mx-auto lg:gap-[4rem] max-w-[71rem]">
          <div className="">
            <img src={keplrLogo} alt="keplr-logo" />
          </div>
          <div className="">
            <img src={beneschLogo} alt="keplr-logo" />
          </div>
          <div className="">
            <img src={esiLogo} alt="keplr-logo" />
          </div>
          <div className="">
            <img src={safetyLogo} alt="keplr-logo" />
          </div>
          <div className="">
            <img src={adventLogo} alt="keplr-logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersSection
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import logo from "../../assets/logo.png"

const NavBar = props => {
  // const NavWrapper = styled("div")`
  //   background: #fafafb;
  //   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3),
  //     0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  //   padding: 1.37rem 3.75rem;
  // `;

  return (
    <div className="bg-[#FAFAFB] p-[1.5rem] lg:px-[3.75rem] lg:py-[1.35rem] flex items-center justify-between z-50">
      <div className="">
        <img
          src={logo}
          alt=""
          className="w-[14.4525rem] h-[4.18075rem] -translate-x-[1.5rem] lg:-translate-x-[0.4rem]"
        />
      </div>
      {/* <div className=''>
          <img src={logo} alt='' className="w-[14.4525rem] h-[4.18075rem]"/>
        </div> */}
    </div>
  );
}

NavBar.propTypes = {}

export default NavBar
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'

const CenteredParagraph = ({children}) => {
  const Paragraph = styled("p")`
    color: #010101;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.00938rem;
  `;

  return (
    <Paragraph>{children}</Paragraph>
  )
}

CenteredParagraph.propTypes = {
  children: PropTypes.node
}

export default CenteredParagraph
import React from 'react'
import PropTypes from 'prop-types'

const Alerts = ({children, forErrors}) => {
  return (
    <div
      className={`p-4 mb-4 text-lg font-Roboto font-medium ${
        forErrors ? "text-red-500" : "text-green-500"
      } rounded-lg bg-blue-50 alert`}
      role="alert"
    >
      {children}
    </div>
  );
}

Alerts.propTypes = {
  children: PropTypes.node,
  forErrors: PropTypes.bool
}

export default Alerts